import React from "react";
import {
  FaArrowRight,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const ServiceDetailsArea3 = () => {
  return (
    <>
      {/* ===================== Service Details Area start ===================== */}
      <div className='service-area pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-12'>
              <div className='td-sidebar service-sidebar'>
                <div className='widget widget_catagory'>
                  <h5 className='widget-title'>
                    <FaArrowRight /> All Service lists
                  </h5>
                  <ul className='catagory-items'>
                    <li>
                      <Link to='/service-details1'> End User Devices And Mobility</Link>
                    </li>
                    <li>
                      <Link to='/service-details2'> Designing a Digital Workspace</Link>
                    </li>
                    <li>
                      <Link to='/service-details3'> ELV Solutions</Link>
                    </li>
                    <li>
                      <Link to='/service-details4'> Enterprise
                        Network Solutions
                      </Link>
                    </li>
                    
                  </ul>
                </div>
                <div className='widget widget_author text-center'>
                  <div className='thumb'>
                    <img src='assets/img/about/9.png' alt='img' />
                  </div>
                  <div className='details'>
                    <a className='btn btn-base border-radius-5' href='#'>
                      Discover our company +
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-8'>
              <div className='blog-details-page-content'>
                <div className='single-blog-inner mb-0'>
                  <div className='thumb'>
                    <img src='assets/img/service/7.png' alt='img' />
                  </div>
                  <div className='details'>
                    
                    
                    <div
                      className='accordion accordion-inner accordion-icon-left mt-3 mb-4'
                      id='accordionExample'
                    >
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingOne'>
                          <button
                            className='accordion-button'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseOne'
                            aria-expanded='true'
                            aria-controls='collapseOne'
                          >
                            Structured Cabling
                          </button>
                        </h2>
                        <div
                          id='collapseOne'
                          className='accordion-collapse collapse show'
                          aria-labelledby='headingOne'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body'>
                            Our structured cabling services can plan, design, Supply, install any scale
                            network. We are committed to provide total connectivity solutions through
                            Structured Cabling with a complete line of Data & Voice Cabling solutions.<br />
                            “Netcom” can help to integrate a fiber-optic network and copper cable
                            network for small to large scale. Our solution has been evolved to embrace
                            new developments in structured cabling for End User, IP Telephone and IP
                            CCTV connectivity within an office or building.
                          </div>
                        </div>
                      </div>
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingTwo'>
                          <button
                            className='accordion-button collapsed'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseTwo'
                            aria-expanded='false'
                            aria-controls='collapseTwo'
                          >
                            Campus Wireless Solution
                          </button>
                        </h2>
                        <div
                          id='collapseTwo'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingTwo'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body'>
                            “Netcom Systems” helps SMBs ,Corporates and institutes with range of
                            advance indoor, outdoor and cloud-managed devices to deliver desired
                            connectivity services securely.<br />
                            We Offer Deployment of an outdoor wireless network which requires an even
                            greater specialized skill set due to the unique nature of the outdoor
                            environment. We offer solution for Point to Point, Point to Multipoint scenarios
                            where multiple challenges could be factor.<br />
                            “Netcom” offers controller based WLAN solution for highly secure, enterprise
                            – scale wireless networks.
                          </div>
                        </div>
                      </div>
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingThree'>
                          <button
                            className='accordion-button collapsed'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseThree'
                            aria-expanded='false'
                            aria-controls='collapseThree'
                          >
                            IPBX/EPABX Systems
                          </button>
                        </h2>
                        <div
                          id='collapseThree'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingThree'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body'>
                            Today’s dynamic enterprise environment requires smarter communication
                            solution for diversified roles of employees. Flexible device usage and
                            round-the-clock connectivity is the need of the hour for the mobile
                            workforce in order to have consistent in-office experience while working
                            from home, between appointments or while on the move.<br />
                            “Netcom” Design and provides IP & EPABX Communication Solution on four
                            pillars of Collaboration, Communication, Messaging and Mobility to
                            enhance customer convenience and business productivity. 
                          </div>
                        </div>
                      </div>
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingFour'>
                          <button
                            className='accordion-button collapsed'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseFour'
                            aria-expanded='false'
                            aria-controls='collapseFour'
                          >
                            CCTV Surveillance
                          </button>
                        </h2>
                        <div
                          id='collapseFour'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingFour'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body'>
                            The intelligent video surveillance solution from “Netcom” offers
                            comprehensive IP video surveillance and Video Analytics services, which
                            are capable of addressing small, medium and large installations. <br />
                            “Netcom” is a trustworthy Security & Surveillance Solutions firm, offering
                            comprehensive security and safety solutions as well as integrated products,
                            systems and services. Our highly skilled team is expert in software
                            application development with state-of-the-art technology domains of
                            Security Surveillance. 
                          </div>
                        </div>
                      </div>

                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingFive'>
                          <button
                            className='accordion-button collapsed'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseFive'
                            aria-expanded='false'
                            aria-controls='collapseFive'
                          >
                            Access Control Systems
                          </button>
                        </h2>
                        <div
                          id='collapseFive'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingFive'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body'>
                            “Netcom Systems” offering, Employee/Visitor Access Management which is
                            intended to address these concerns in a professional and orderly strategy
                            without trading off security, productivity or hospitality. In our Solution Access
                            Control and Time-Attendance suite are runs freely on client’s shrewd
                            gadgets. 
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===================== Service Details Area End ===================== */}
    </>
  );
};

export default ServiceDetailsArea3;
