import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./pages/About";
import Contact from "./pages/Contact";
import HomeOne from "./pages/MainHome";
import Service from "./pages/Service";
import ServiceDetailsCat1 from "./pages/ServiceDetailsCat1";
import ServiceDetailsCat2 from "./pages/ServiceDetailsCat2";
import ServiceDetailsCat3 from "./pages/ServiceDetailsCat3";
import ServiceDetailsCat4 from "./pages/ServiceDetailsCat4";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";
function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<HomeOne />} />
        <Route exact path='/about' element={<About />} />
        <Route exact path='/contact' element={<Contact />} />
        <Route exact path='/service' element={<Service />} />
        <Route exact path='/service-details1' element={<ServiceDetailsCat1 />} />
        <Route exact path='/service-details2' element={<ServiceDetailsCat2 />} />
        <Route exact path='/service-details3' element={<ServiceDetailsCat3 />} />
        <Route exact path='/service-details4' element={<ServiceDetailsCat4 />} />
      </Routes>
      <ScrollToTop smooth color='#803434' />
    </BrowserRouter>
  );
}

export default App;