import React from "react";
import AboutAreaDetail from "../components/AboutAreaDetails";
import AboutArea from "../components/AboutArea";
import Breadcrumb from "../components/Breadcrumb";
import NavBar from "../components/NavBar";
import FooterThree from "../components/FooterThree";
import PartnerClient from "../components/PartnerClient";

const About = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      <Breadcrumb title={"About Us"} />

      {/* About Area One */}
      <AboutArea />

      <AboutAreaDetail />

      {/* Partner Client Area One */}
      <PartnerClient /> 

      {/* Footer Three */}
      <FooterThree />
    </>
  );
};

export default About;
