import React from "react";
import Marquee from "react-fast-marquee";
const PartnerSlider = () => {
  return (
    <div className='about-area bg-gray pt-4 pb-4'>
      <div className='container'>
        <div className='client-slider'>
          <div className="pd-bottom-10 pd-top-10 ">
          <h5>Partner</h5>
          </div>
          <Marquee gradient={false}>
            <div className='thumb'>
              <img src='assets/img/partner/1.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/partner/2.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/partner/3.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/partner/4.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/partner/5.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/partner/6.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/partner/7.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/partner/8.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/partner/9.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/partner/10.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/partner/11.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/partner/12.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/partner/13.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/partner/14.png' alt='img' />
            </div>
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default PartnerSlider;
