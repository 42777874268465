import React from "react";
import {FaChevronRight} from "react-icons/fa";
import { FaLocationDot,FaEnvelope, FaPhone } from "react-icons/fa6";
import { Link } from "react-router-dom";

const FooterThree = () => {
  return (
    <>
      {/* ================== Footer Two Start ==================*/}
      <footer className='footer-area bg-black mt-0 pd-top-100'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-3 col-lg-4 col-md-6'>
              <div className='widget widget_about'>
                <div className='thumb'>
                  <img src='assets/img/logo2.png' alt='img' />
                </div>
                <div className='details'>
                  <p>
                    Netcom Systems, FF/1, First Floor, Akshat Avenue, Opp.Revati
                    Tower, B/h Courtyard Marriott Hotel, Ramdev nagar cross Road,
                    Satellite, Ahmedabad -380015.
                  </p>
                  {/* <ul className='social-media'>
                    <li>
                      <a href='#'>
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaYoutube />
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-lg-4 offset-xl-2 col-md-6 ps-xl-5'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>Our Service</h4>
                <ul>
                  <li>
                    <Link to='/service-details1'>
                      <FaChevronRight /> End User Devices
                      And Mobility
                    </Link>
                  </li>
                  <li>
                    <Link to='/service-details2'>
                      <FaChevronRight /> Designing a
                      Digital Workspace
                    </Link>
                  </li>
                  <li>
                    <Link to='/service-details3'>
                      <FaChevronRight /> ELV
                      Solutions
                    </Link>
                  </li>
                  <li>
                    <Link to='/service-details4'>
                      <FaChevronRight /> Enterprise
                      Network Solutions
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className='col-xl-3 col-lg-4 offset-xl-1 col-md-6'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>Contact Us</h4>
                <ul>
                  <li>
                    <Link to='#'>
                      <FaPhone />  +91 94294 27767
                    </Link>
                  </li>
                  <li>
                    <Link to='#'>
                      <FaEnvelope />  info@netcomsys.in
                    </Link>
                  </li>
                  <li>
                    <Link to='#'>
                      <FaLocationDot />   Satellite, Ahmedabad

                    </Link>
                  </li>
                  
                </ul>
              </div>
            </div>
            
              
            </div>
          </div>
        <div className='footer-bottom bg-gray-black'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 align-self-center'>
                <p>© Netcom Systems Systems 2023 | All Rights Reserved</p>
              </div>
              {/* <div className='col-md-6 text-lg-end'>
                <a href='#'>Trams &amp; Condition</a>
                <a href='#'>Privacy Policy</a>
                <a href='#'>Contact Us</a>
              </div> */}
            </div>
          </div>
        </div>
      </footer>

      {/* ================== Footer Two  end ==================*/}
    </>
  );
};

export default FooterThree;
