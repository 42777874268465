import React from "react";

const AboutArea = () => {
  return (
    <>
      {/* ================== AboutAreaOne start  ==================*/}
      <div className='about-area pd-top-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div
                className='about-thumb-inner pe-xl-5 me-xl-5 '
                data-aos='fade-right'
                data-aos-delay='100'
                data-aos-duration='1500'
              >
                <img
                  className='animate-img-1 top_image_bounce'
                  src='assets/img/about/2.png'
                  alt='img'
                />
                <img
                  className='animate-img-2 left_image_bounce'
                  src='assets/img/about/3.png'
                  alt='img'
                />
                <img
                  className='animate-img-3 top_image_bounce'
                  src='assets/img/banner/5.svg'
                  alt='img'
                />
                <img
                  className='main-img'
                  src='assets/img/about/1.png'
                  alt='img'
                />
              </div>
            </div>
            <div
              className='col-lg-6 '
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mt-5 mt-lg-0'>
                <h6 className='sub-title'>ABOUT US</h6>
                <h2 className='title'>
                  Our <span>computer service </span> is here to provide you with expert assistance.  
                 
                </h2>
                <p className='content mb-4 mb-xl-5'>
                  Founded in the year 2014, “Netcom Systems” has successfully carved its
                  position as one of the most reliableIT system Integrator and consulting
                  organization with Head Quarter in Ahmedabad and presence across
                  Gujarat. We specialize in End user devices & Mobility, Enterprise Networking,
                  Server & Storage, ELV Solutions, Data Center Services and more to facilitate
                  our customers across Gujarat
                </p>
                <p className='content mb-4 mb-xl-5'>
                  We have a team of experts and process driven professionals focusing on
                  delighting our clients with our excellent services and Principles
                </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================== AboutAreaOne End  ==================*/}
    </>
  );
};

export default AboutArea;
