import React from "react";
import {
  FaArrowRight,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const ServiceDetailsArea1 = () => {
  return (
    <>
      {/* ===================== Service Details Area start ===================== */}
      <div className='service-area pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-12'>
              <div className='td-sidebar service-sidebar'>
                <div className='widget widget_catagory'>
                  <h5 className='widget-title'>
                    <FaArrowRight /> All Service lists
                  </h5>
                  <ul className='catagory-items'>
                    <li>
                      <Link to='/service-details1'> End User Devices And Mobility</Link>
                    </li>
                    <li>
                      <Link to='/service-details2'> Designing a Digital Workspace</Link>
                    </li>
                    <li>
                      <Link to='/service-details3'> ELV Solutions</Link>
                    </li>
                    <li>
                      <Link to='/service-details4'> Enterprise
                        Network Solutions
</Link>
                    </li>
                    
                  </ul>
                </div>
                <div className='widget widget_author text-center'>
                  <div className='thumb'>
                    <img src='assets/img/about/9.png' alt='img' />
                  </div>
                  <div className='details'>
                    <a className='btn btn-base border-radius-5' href='#'>
                      Discover our company +
                    </a>
                  </div>
                </div>

                
                
              </div>
            </div>
            <div className='col-lg-8'>
              <div className='blog-details-page-content'>
                <div className='single-blog-inner mb-0'>
                  <div className='thumb'>
                    <img src='assets/img/service/7.png' alt='img' />
                  </div>
                  <div className='details'>
                   
                    <p>
                      “Netcom Systems” provides Sales and support in diverse areas of IT. We extend our Sales
                      and services on all the latest platforms. Our team of qualified personnel knows how to take
                      care of your needs and requirements.Our aim is to offer highest possible services
                      guaranteed with 100% satisfaction.
                    </p>
                    <p>
                      We build and maintain IT Infrastructure, Applications & analytics services. We understand
                      your needs to integrate and support-high quality & cost optimized IT solutions.
                    </p>
                    
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===================== Service Details Area End ===================== */}
    </>
  );
};

export default ServiceDetailsArea1;
