import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import ContactMain from "../components/ContactMain";
import NavBar from "../components/NavBar";
import FooterThree from "../components/FooterThree";
import PartnerClient from "../components/PartnerClient";

const Contact = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      <Breadcrumb title={"Contact"} />

      {/* Contact Main */}
      <ContactMain />

      {/* Partner Client Area One */}
      <PartnerClient /> 

      {/* Footer Three */}
      <FooterThree />
    </>
  );
};

export default Contact;
