import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import NavBar from "../components/NavBar";
import ServiceAreaGroup from "../components/ServiceAreaGroup";
import FooterThree from "../components/FooterThree";
import PartnerClient from "../components/PartnerClient";

const Service = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      <Breadcrumb title={"Service"} />

      {/* Service Area One */}
      <ServiceAreaGroup />

      {/* Partner Client Area One */}
      <PartnerClient /> 

      {/* Footer Three */}
      <FooterThree />
     
    </>
  );
};

export default Service;
