import React from "react";
import PartnerSlider from "../components/PartnerSlider"

import BrandAreaOne from "../components/BrandAreaOne";
const PartnerClient = () => {
  return (
    <div>
      {/* Partner Area One */}
        <PartnerSlider />
      
      <div className="pd-top-30"></div>
      
      {/* Brand Area One */}
        <BrandAreaOne />
      
    </div>
  );
};

export default PartnerClient;
