import React from "react";

const AboutAreaOne = () => {
  return (
    <>
      {/* ================== AboutAreaOne start  ==================*/}
      <div className='about-area pd-top-120'>
        <div className='container'>
          <div className='row'>
              <div className='section-title mt-5 mt-lg-0'>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className='single-about-inner'>
                      <div className='thumb mb-3'>
                      <img src='assets/img/icon/vision.png' alt='img' />
                      </div>
                      <div className='details'>
                      <h5>OUR VISION</h5>
                        <p>
                        To become a leading, one-stop IT services provider helping clients meet their
                        primary business objectives.
                      </p>
                      <br />
                      <p>
                        To provide cost effective yet premium quality sales and support.
                      </p>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='single-about-inner'>
                      <div className='thumb mb-3'>
                        <img src='assets/img/icon/mission.png' alt='img' />
                      </div>
                      <div className='details'>
                      <h5>OUR MISSION</h5>
                        <p>
                        To build and facilitate best-in-class service offerings with advanced technologies
                        that brings in concrete business benefits to our customers.
                      </p>
                      <br />
                      <p>
                        To dedicatedly deliver professional services to ensure customer satisfaction and
                        success.
                      </p>
                      </div>
                    </div>
                </div>
                <div className='col-md-4'>
                  <div className='single-about-inner'>
                    <div className='thumb mb-3'>
                      <img src='assets/img/icon/values.png' alt='img' />
                    </div>
                    <div className='details'>
                      <h5>OUR CORE VALUES</h5>
                      <p>
                        “Netcom Systems” blends latest technology expertise and industry-specific insights
                        into how technology impacts people and processes to provide solutions. “Netcom”s
                        business ethics are very important and we expect to demonstrate these core values
                        in our work.

                      </p>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* ================== AboutAreaOne End  ==================*/}
    </>
  );
};

export default AboutAreaOne;
