import React from "react";
import AboutArea from "../components/AboutArea";
import BannerOne from "../components/BannerOne";
import FooterThree from "../components/FooterThree";
import NavBar from "../components/NavBar";
import ServiceAreaOne from "../components/ServiceAreaGroup";
import PartnerClient from "../components/PartnerClient";

const MainHome = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Banner One */}
      <BannerOne />

      {/* About Area One */}
      <AboutArea />

      {/* Service Area One */}
      <ServiceAreaOne />

      {/* Partner Client Area One */}
      <PartnerClient />

      {/* Footer Three */}
      <FooterThree />
    </>
  );
};

export default MainHome;
