import React from "react";
import {
  FaArrowRight,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const ServiceDetailsArea4 = () => {
  return (
    <>
      {/* ===================== Service Details Area start ===================== */}
      <div className='service-area pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-12'>
              <div className='td-sidebar service-sidebar'>
                <div className='widget widget_catagory'>
                  <h5 className='widget-title'>
                    <FaArrowRight /> All Service lists
                  </h5>
                  <ul className='catagory-items'>
                    <li>
                      <Link to='/service-details1'> End User Devices And Mobility</Link>
                    </li>
                    <li>
                      <Link to='/service-details2'> Designing a Digital Workspace</Link>
                    </li>
                    <li>
                      <Link to='/service-details3'> ELV Solutions</Link>
                    </li>
                    <li>
                      <Link to='/service-details4'> Enterprise
                        Network Solutions
</Link>
                    </li>
                    
                  </ul>
                </div>
                <div className='widget widget_author text-center'>
                  <div className='thumb'>
                    <img src='assets/img/about/9.png' alt='img' />
                  </div>
                  <div className='details'>
                    <a className='btn btn-base border-radius-5' href='#'>
                      Discover our company +
                    </a>
                  </div>
                </div>

                
                
              </div>
            </div>
            <div className='col-lg-8'>
              <div className='blog-details-page-content'>
                <div className='single-blog-inner mb-0'>
                  <div className='thumb'>
                    <img src='assets/img/service/7.png' alt='img' />
                  </div>
                  <div className='details'>
                    {/* <h4>
                      Making this the first true generator on the Internet
                    </h4>
                    <p>
                      Cras varius. Donec vitae orci sed dolor rutrum auctor.
                      Fusce egestas elit eget lorem. Suspendisse nisl elit,
                      rhoncus eget elementum acondimentum eget, diam. Nam at
                      tortor in tellus interdum sagitliquam lobortis. Donec orci
                      lectus, aliquam ut, faucibus non, euismod id, nulla.
                      Curabitur blandit mollis lacus. Nam adipiscing. Vestibulum
                      eu odio. Vivamus laoreet.
                    </p> */}
                    
                    <div
                      className='accordion accordion-inner accordion-icon-left mt-3 mb-4'
                      id='accordionExample'
                    >
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingOne'>
                          <button
                            className='accordion-button'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseOne'
                            aria-expanded='true'
                            aria-controls='collapseOne'
                          >
                            Data Center Solutions
                          </button>
                        </h2>
                        <div
                          id='collapseOne'
                          className='accordion-collapse collapse show'
                          aria-labelledby='headingOne'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body'>
                            “Netcom Systems” can help you to provide state of art Data Centre
                            Infrastructure, our industry aligned technology architects provide solutions
                            that work and adds exceptional value. We understand business intricacy,
                            we know our technology and we know how to bring this knowledge and
                            understanding together to provide end-to end Datacenter Infrastructure
                            solution and integrating it to work seamlessly.
                          </div>
                        </div>
                      </div>
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingTwo'>
                          <button
                            className='accordion-button collapsed'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseTwo'
                            aria-expanded='false'
                            aria-controls='collapseTwo'
                          >
                            Managed Network Services
                          </button>
                        </h2>
                        <div
                          id='collapseTwo'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingTwo'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body'>
                            “Netcom Systems” is prominent in Managed network Service provider
                            based in Ahmedabad, offering its customers best-in-class solutions in
                            Switching, Routing & Wireless networking along with WAN Acceleration
                            technologies. We can provide our customers aid in proffering control of their
                            networks at the same time increasing productivity at a price that eventually
                            gets you high return on investment. With the experienced & certified team of
                            “Netcom” you can deploy technologically modern, multilayer, next
                            generation & hierarchical networks. “Netcom” serves you in acquiring
                            controlling network & security services to a lot of the world’s largest &
                            rapidly-growing MNCs. We assure you that ,we would convert your networks,
                            platforms and applications into a single, integrated environment that
                            makes your business futuristic.
                          </div>
                        </div>
                      </div>
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingThree'>
                          <button
                            className='accordion-button collapsed'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseThree'
                            aria-expanded='false'
                            aria-controls='collapseThree'
                          >
                            Collaboration Solutions
                          </button>
                        </h2>
                        <div
                          id='collapseThree'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingThree'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body'>
                            Our wide collection of collaboration solutions helps our customer to
                            increase their productivity and saving their valuable time and money.
                            Our Collaboration solutions umbrella includes “Conference solutions, Audio
                            Visual solutions, Boardroom solutions, PA systems etc.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===================== Service Details Area End ===================== */}
    </>
  );
};

export default ServiceDetailsArea4;
