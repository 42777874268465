import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import NavBar from "../components/NavBar";
import ServiceDetailsArea from "../components/ServiceDetailsArea4";
import FooterThree from "../components/FooterThree";
import PartnerClient from "../components/PartnerClient";

const ServiceDetailsCat4 = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      <Breadcrumb title={"Enterprise Network Solutions"} />

      {/* Service Details Area */}
      <ServiceDetailsArea />

      {/* Partner Client Area One */}
      <PartnerClient /> 

      {/* Footer Three */}
      <FooterThree />
    </>
  );
};

export default ServiceDetailsCat4;
