import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import NavBar from "../components/NavBar";
import ServiceDetailsArea from "../components/ServiceDetailsArea1";
import FooterThree from "../components/FooterThree";
import PartnerClient from "../components/PartnerClient";

const ServiceDetailsCat1 = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      <Breadcrumb title={"End User Devices And Mobility"} />

      {/* Service Details Area */}
      <ServiceDetailsArea />

      {/* Partner Client Area One */}
      <PartnerClient /> 

      {/* Footer Three */}
      <FooterThree />
    </>
  );
};

export default ServiceDetailsCat1;