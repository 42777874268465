import React from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

const ServiceAreaGroup = () => {
  return (
    <>
      {/*=================== service area start ===================*/}
      <div className='service-area bg-relative pd-top-100'>
        <img
          className='position-bottom-left top_image_bounce'
          src='assets/img/icon/4.png'
          alt='img'
        />
        <div className='container'>
          <div className='section-title text-center'>
            <h6 className='sub-title'>CREATIVE SERVICES</h6>
            <h2 className='title'>
              Your Partner In <span>Digital</span> Success
            </h2>
          </div>
          <div className='row'>
              <div className='col-lg-3 col-md-6' key="1">
                <div className='single-service-inner text-center'>
                  <div className='thumb'>
                  <img src='assets/img/service-icon/14.png' style={{ width: '80px', height: '80px' }} alt='img' />
                  </div>
                  <div className='details'>
                    <h5>
                    <Link to='/service-details1'>End User Devices
                      And Mobility</Link>
                    </h5>
                    {/* <p>{data.des}</p> */}
                    <Link className='btn btn-border-base' to='/service-details1'>
                      Touch More <FaPlus />
                    </Link>
                  </div>
                </div>
            </div>
            
            <div className='col-lg-3 col-md-6' key="2">
              <div className='single-service-inner text-center'>
                <div className='thumb'>
                  <img src='assets/img/service-icon/2.png' style={{ width: '80px', height: '80px' }} alt='img' />
                </div>
                <div className='details'>
                  <h5>
                    <Link to='/service-details2'>Designing a
                      Digital Workspace</Link>
                  </h5>
                  {/* <p>{data.des}</p> */}
                  <Link className='btn btn-border-base' to='/service-details2'>
                    Touch More <FaPlus />
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6' key="4">
              <div className='single-service-inner text-center'>
                <div className='thumb'>
                  <img src='assets/img/service-icon/3.png' style={{ width: '80px', height: '80px' }}  alt='img' />
                </div>
                <div className='details'>
                  <h5>
                    <Link to='/service-details3'>ELV<br />
                      Solutions</Link>
                  </h5>
                  {/* <p>{data.des}</p> */}
                  <Link className='btn btn-border-base' to='/service-details3'>
                    Touch More <FaPlus />
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6' key="4">
              <div className='single-service-inner text-center'>
                <div className='thumb'>
                  <img src='assets/img/service-icon/1.png' style={{ width: '80px', height: '80px' }} alt='img' />
                </div>
                <div className='details'>
                  <h5>
                    <Link to='/service-details4'>Enterprise
                      Network Solutions</Link>
                  </h5>
                  {/* <p>{data.des}</p> */}
                  <Link className='btn btn-border-base' to='/service-details4'>
                    Touch More <FaPlus />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =================== service area end ===================*/}
    </>
  );
};

export default ServiceAreaGroup;
